<template>
  <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
    <el-row>
      <el-form-item label="关键词" prop="keyword">
        <el-col>
          <el-input v-model="querys.keyword" prefix-icon="Search"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="" label-width="20px">
        <el-button type="primary" icon="search" @click="fetchs">
          搜索
        </el-button>
      </el-form-item>
    </el-row>
  </el-form>

  <static-table ref="table" class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
    :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler" :seleHandler="seleHandler"
    :checkedRows="selects">
    <template v-slot:category="scope">
      <span v-if="scope.row.category == 'global'">通用</span>
    </template>
    <template v-slot:birthday="scope">
      <i class="el-icon-time" v-show="scope.row.birthday"></i>

      <span style="margin-left: 10px">{{ (scope.row.birthday || "").split(" ")[0] }}</span>
    </template>
  </static-table>
</template>
<script>
import { fetchRoles } from "@/api/sys";
import StaticTable from "@/components/static-table.vue";

export default {
  name: "UserRole",
  components: { StaticTable },
  props: {

  },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "selection" },
          { type: "index", label: "序号" },
          { type: "template", prop: "category", label: "类别", width: "120", sortable: true, searchable: true, },
          { prop: "name", label: "角色名", width: "150", sortable: true, searchable: true, },
          { prop: "code", label: "角色编号", sortable: true, searchable: true, width: "110", },
          { prop: "sortCode", label: "排序号", sortable: true, width: "100", },
          { prop: "remark", label: "备注", sortable: true, showOverflowTooltip: true },
          { prop: "createDate", label: "创建时间", width: "150" },
        ],
        selects: [],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      checkedKeys: [],
    };
  },
  methods: {
    fetchs() {
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      fetchRoles(search).then((res) => {
        this.pager = { ...res.data };

        //this.querys.selects = this.pager.data.filter(item => this.checkedKeys.indexOf(item.id) >= 0);
        //console.log('selects', this.querys.selects);

        //this.$refs.table.queryCheckeds();

        //this.$refs.table.setCheckeds(this.querys.selects);
        // console.log('selects', selects);
        // this.selects.forEach(item=>{

        // })
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    seleHandler(rows) {
      this.querys.selects = rows;
    },
    getSelectRows() {
      return this.querys.selects;
    },
    setCheckedKeys(keys) {
      this.checkedKeys = keys;
    }
  },
  mounted() {
    this.fetchs();
  },
  computed: {
    selects: function () {
      if (this.pager.data)
        return this.pager.data.filter(item => this.checkedKeys.indexOf(item.id) >= 0);

      return [];
    }
  }
};
</script>